<template>
  <div>


    <div class="pxkeji-right-box pxkeji-set">
      <a-spin :tip="loadingTip" :spinning="isloading">
        <a-form :form="form">
          <a-row class="items">
            <a-col :span="24">
              <a-input
                addonBefore="安卓"
                v-model="info.andriodVersion"
                addonAfter="版本号"
                style="width: 250px;text-align:center"
              />&nbsp;&nbsp;&nbsp;&nbsp;
              <a-input
                addonBefore="版本码"
                v-model="info.andriodVersionCode"
                style="width: 150px;text-align:center"
              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a-input
                addonBefore="地址"
                v-model="info.andriodUrl"
                style="width: 350px;text-align:center"
              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </a-col>
          </a-row>
          <a-row class="items">
            <a-col :span="24">
              <a-input
                addonBefore="iOS"
                v-model="info.iosVersion"
                addonAfter="版本号"
                style="width: 250px;text-align:center"
              />&nbsp;&nbsp;&nbsp;&nbsp;
              <a-input
                addonBefore="版本码"
                v-model="info.iosVersionCode"
                style="width: 150px;text-align:center"
              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a-input
                addonBefore="地址"
                v-model="info.iosUrl"
                style="width: 350px;text-align:center"
              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </a-col>
          </a-row>
          <a-form-item :wrapper-col="{ span: 24, offset: 10 }" style="margin-top:20px;">
            <pxkeji-btn name="保存" type="primary" htmlType="submit" @click="handleSubmit" />
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>
<script>
var that;
export default {
  name: "appVersion",
  data() {
    return {
      form: this.$form.createForm(this),
      isloading: false,
      loadingTip: "加载中...",
      info: {
        andriodVersion: "",
        andriodUrl: "",
        andriodVersionCode: "",
        iosVersion: "",
        iosUrl: "",
        iosVersionCode: ""
      }
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      that = this;
      this.getAppVersion();
    });
  },
  watch: {},
  methods: {
    error(msg) {
      this.$error({
        title: "提示",
        content: msg == undefined ? "服务器异常" : msg,
        okText: "返回",
        centered: true,
        onOk() {
          that.$emit("callbackMethod");
        }
      });
    },
    getAppVersion() {
      this.isloading = true;
      this.$api.appVersion
        .getAppVersion()
        .then(res => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            if (res.data != null) this.info = res.data;
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    /**提交 */
    handleSubmit() {
      this.isloading = true;

     // console.log("提交", this.info);
   

      this.$api.appVersion
        .setAppVersion(
          this.info.andriodVersion,
          this.info.andriodUrl,
          this.info.andriodVersionCode,
          this.info.iosVersion,
          this.info.iosUrl,
          this.info.iosVersionCode
        )
        .then(res => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.$message.success(res.errorMsg);
            this.GetData();
          } else {
            this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    //子页面刷新
    save(data) {}
  }
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.items {
  /* margin: 15px 0; */
  border-bottom: 1px #e8e8e8 solid;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.85);
}
.selectStyle {
  width: 200px !important;
  display: inline-block;
  margin-left: 5px;
}
.rechargeIpt {
  width: 120px;
  margin: 0 20px 0 5px;
}
.costIpt {
  width: 180px;
  margin: 0 20px 0 5px;
}
.templateItem {
  float: left;
  margin-left: 10px;
  margin-right: 20px;
}
.temBtn {
  cursor: pointer;
  color: #1890ff;
}
.itemBtns .ant-btn-primary {
  margin: 0 5px;
}

.pxkeji-set input {
  text-align: center;
}
.pxkeji_ml {
  margin-left: 20px;
}
</style>

